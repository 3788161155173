<template>
    <div id="ranking" ref="ranking">
        
        <div class="ranking">
            <div class="content">
                
                <button style="display: none;" v-on:click="$app.modalI('Título do vídeo',my_link_video)">Assista</button>


                <div class="ranking-header">
                    <h1 class="ranking-title">{{ $t('ranking.ranking') }} </h1>
<!--                    <a class="alignVertical pointer" v-on:click="$app.openRegulamento()">
                        <svg
                             width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.0004 0.400024C4.69741 0.400024 0.399414 4.69802 0.399414 10C0.399283 11.2609 0.64753 12.5094 1.12998 13.6743C1.61243 14.8392 2.31963 15.8977 3.21119 16.7892C4.10275 17.6808 5.16121 18.388 6.32611 18.8705C7.49102 19.3529 8.73956 19.6012 10.0004 19.601C15.3014 19.601 19.6004 15.303 19.6004 10C19.6004 4.69802 15.3014 0.400024 10.0004 0.400024ZM10.8964 3.86602C11.8324 3.86602 12.1074 4.40902 12.1074 5.03002C12.1074 5.80502 11.4874 6.52202 10.4284 6.52202C9.54241 6.52202 9.12041 6.07702 9.14641 5.34002C9.14641 4.71902 9.66541 3.86602 10.8964 3.86602ZM8.49841 15.75C7.85841 15.75 7.39141 15.361 7.83841 13.656L8.57141 10.631C8.69841 10.147 8.71941 9.95302 8.57141 9.95302C8.38041 9.95302 7.54941 10.287 7.05941 10.617L6.74041 10.094C8.29541 8.79502 10.0834 8.03302 10.8484 8.03302C11.4884 8.03302 11.5944 8.78902 11.2754 9.95302L10.4354 13.133C10.2864 13.695 10.3504 13.889 10.4994 13.889C10.6914 13.889 11.3194 13.657 11.9374 13.17L12.2994 13.656C10.7864 15.168 9.13741 15.75 8.49841 15.75Z"
                                  fill="var(&#45;&#45;buttons_color)"/>
                        </svg>
                    </a>-->
                </div>

                <div class="ranking__imgHeader flex flex-wrap">
                    <div class="swiper-container ranking__imgHeader--swiper">
                        <div class="swiper-wrapper">
                            <div v-for="(item, index) in reward" :key="`reward_${index}`" class="swiper-slide">
                                <img :src="item.image">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div v-if="my_ranking_daily && (Date.now() >=  (new Date(my_ranking_daily_ini)).getTime() && Date.now() <=  (new Date(my_ranking_daily_fin)).getTime())" class="container-master-ranking">
                    <div class="container-table-ranking">
                        <div class="ranking__table--box flex flex-wrap border">
                            <div class="container-header-ranking">
                                <div class="col-6 alignVerticalStart">
                                    <p><b>{{ $t('ranking.rankingDoDia') }}</b></p>
                                </div>
                                <div class="col-6 alignVerticalEnd">

                                    <p style="font-size: 12px">{{ $t('ranking.publicadoAs') }} {{ timestamp_daily }}</p>
                                </div>
                            </div>
                            <div class="container-gifts">
                                <div class="content-gift">
                                    <!-- <img :src="my_daily_reward_image"> -->
                                    <div class="present-desktop">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.58984 10H2.58984V18C2.58984 18.5304 2.80056 19.0391 3.17563 19.4142C3.5507 19.7893 4.05941 20 4.58984 20H9.58984V10H3.58984ZM16.5898 10H11.5898V20H16.5898C17.1203 20 17.629 19.7893 18.0041 19.4142C18.3791 19.0391 18.5898 18.5304 18.5898 18V10H16.5898ZM17.3808 5C17.5275 4.51378 17.598 4.00779 17.5898 3.5C17.5898 1.57 16.0198 0 14.0898 0C12.4678 0 11.3848 1.482 10.6858 3.085C9.99684 1.57 8.85884 0 7.08984 0C5.15984 0 3.58984 1.57 3.58984 3.5C3.58984 4.096 3.66884 4.589 3.79884 5H0.589844V9H9.58984V7H11.5898V9H20.5898V5H17.3808ZM5.58984 3.5C5.58984 2.673 6.26284 2 7.08984 2C7.97784 2 8.80384 3.525 9.28784 5H6.58984C6.21584 5 5.58984 5 5.58984 3.5ZM14.0898 2C14.9168 2 15.5898 2.673 15.5898 3.5C15.5898 5 14.9638 5 14.5898 5H12.1128C12.6228 3.424 13.3638 2 14.0898 2Z"
                                                  fill="var(--buttons_color)"/>
                                        </svg>
                                    </div>
                                    <div class="present-mobile">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.8574 28.8627H11.1431M3.04785 4.48178V7.49749C3.04785 9.49773 3.79353 11.4266 5.14018 12.9099L7.90499 15.9552M28.9526 4.48178V7.49749C28.9526 9.49773 28.2069 11.4266 26.8603 12.9099L24.0955 15.9552M7.90499 3.04761H24.0955V14.3417C24.0955 18.7971 20.4711 22.409 16.0002 22.409C11.5294 22.409 7.90499 18.7971 7.90499 14.3417V3.04761Z"
                                                  stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <span><b class="text-premiation desktop" >{{ $t('ranking.premiacao') }}</b></span>
                                        <span class="text-premiation" >{{my_daily_reward_description}}</span>
                                    </div>
                                </div>
                                <div class="content-gift container-time">
                                    <span class="rest-time">{{ $t('ranking.tempoRestante') }}</span>
                                    <span class="time">{{ relogio_restante_dia }}</span>
                                </div>
                            </div>
                            <div class="ranking__table--box---youRank flex col-12 new-layout-person">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <div class="position">
                                            <h3>{{ my_daily_ranking }}º</h3>
                                        </div>
                                        
                                        <!-- <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                                :src="item.thumb" alt="imagem do meu personagem"> -->
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <img :src="avatar" alt="imagem do meu personagem">
                                        <p><b>{{ nickname }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b>{{my_daily_point}}</b><span class="points" style="margin-left: 6px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="white"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="white"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="white"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="white"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="white"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="white"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div v-for="(item, index) in list_daily" :key="index" class="ranking__table--box---placar flex col-12 layout-ranking">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <div class="position">
                                            <h3>{{ item ? item.ranking : '' }}º</h3>
                                        </div>
                                        <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''" :src="item.thumb" alt="imagem do meu personagem">
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <p class="player-name"><b>{{ item ? item.nickname : '' }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b style="color: var(--buttons_color);font-size: 14px">{{ item ? item.daily_point : '' }}</b><span class="points" style="margin-left: 6px;color: #474747;font-size: 10px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="white"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="white"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="white"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="white"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="white"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="white"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="my_ranking_general && (Date.now() >=  (new Date(my_ranking_general_ini)).getTime() && Date.now() <=  (new Date(my_ranking_general_fin)).getTime())" class="container-master-ranking">
                    <div class="container-table-ranking">
                        <div class="ranking__table--box flex flex-wrap border">
                            <div class="container-header-ranking">
                                <div class="col-6 alignVerticalStart">
                                    <p><b>{{ $t('ranking.rankingGeral') }}</b></p>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <p class="publicated" style="font-size: 14px; font-weight: 300;">{{ $t('ranking.publicadoAs') }} {{ timestamp_general }}</p>
                                </div>
                            </div>

                            <div class="container-gifts">
                                <div class="content-gift">
                                    <!-- <img :src="my_general_reward_image"> -->
<!--                                    <div class="present-desktop">
                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.58984 10H2.58984V18C2.58984 18.5304 2.80056 19.0391 3.17563 19.4142C3.5507 19.7893 4.05941 20 4.58984 20H9.58984V10H3.58984ZM16.5898 10H11.5898V20H16.5898C17.1203 20 17.629 19.7893 18.0041 19.4142C18.3791 19.0391 18.5898 18.5304 18.5898 18V10H16.5898ZM17.3808 5C17.5275 4.51378 17.598 4.00779 17.5898 3.5C17.5898 1.57 16.0198 0 14.0898 0C12.4678 0 11.3848 1.482 10.6858 3.085C9.99684 1.57 8.85884 0 7.08984 0C5.15984 0 3.58984 1.57 3.58984 3.5C3.58984 4.096 3.66884 4.589 3.79884 5H0.589844V9H9.58984V7H11.5898V9H20.5898V5H17.3808ZM5.58984 3.5C5.58984 2.673 6.26284 2 7.08984 2C7.97784 2 8.80384 3.525 9.28784 5H6.58984C6.21584 5 5.58984 5 5.58984 3.5ZM14.0898 2C14.9168 2 15.5898 2.673 15.5898 3.5C15.5898 5 14.9638 5 14.5898 5H12.1128C12.6228 3.424 13.3638 2 14.0898 2Z"
                                                  fill="var(&#45;&#45;buttons_color)"/>
                                        </svg>
                                    </div>
                                    <div class="present-mobile">
                                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M20.8574 28.8627H11.1431M3.04785 4.48178V7.49749C3.04785 9.49773 3.79353 11.4266 5.14018 12.9099L7.90499 15.9552M28.9526 4.48178V7.49749C28.9526 9.49773 28.2069 11.4266 26.8603 12.9099L24.0955 15.9552M7.90499 3.04761H24.0955V14.3417C24.0955 18.7971 20.4711 22.409 16.0002 22.409C11.5294 22.409 7.90499 18.7971 7.90499 14.3417V3.04761Z"
                                                  stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>-->
                                    <div>
<!--                                        <span><b class="text-premiation desktop" >{{ $t('ranking.premiacao') }}</b></span>-->
                                        <span class="text-premiation" v-html="regulation"></span>
                                    </div>
                                </div><!--
                                <div class="content-gift container-time">
                                    <span class="rest-time">{{ $t('ranking.tempoRestante') }}</span>
                                    <span class="time">{{ relogio_restante_geral }}</span>
                                </div>-->
                            </div>

                            <div class="ranking__table--box---youRank flex col-12 new-layout-person">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <div class="position">
                                            <h3>{{ my_general_ranking }}º</h3>
                                        </div>
                                        <!-- <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''" :src="item.thumb" alt="imagem do meu personagem"> -->
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <img :src="avatar" style="background-position: center; background-size: cover;" alt="imagem do meu personagem">
                                        <p><b>{{ nickname }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b>{{my_point}}</b><span class="points" style="margin-left: 6px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="white"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="white"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="white"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="white"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="white"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="white"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="white"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div v-for="(item, index) in list_general" :key="index" class="ranking__table--box---placar flex col-12 layout-ranking">
                                <div class="col-6 alignVerticalStart flex">
                                    <div class="container-character-especification">
                                        <div class="position">
                                            <h3>{{ item ? item.ranking : '' }}º</h3>
                                        </div>
                                        <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                        :src="item.thumb" alt="imagem do meu personagem">
                                        <!-- <img src="../../public/assets/icons/ranking/my-character.svg" alt="imagem do meu personagem"> -->
                                        <p class="player-name"><b>{{ item ? item.nickname : '' }}</b></p>
                                    </div>
                                </div>
                                <div class="col-6 alignVerticalEnd">
                                    <div class="col-10 alignVerticalEnd">
                                        <p><b style="color: var(--buttons_color);font-size: 14px">{{ item ? item.total_points : '' }}</b><span class="points" style="margin-left: 6px;color: #474747;font-size: 10px;">pts</span></p>
                                    </div>
                                    <div class="col-2 alignVerticalEnd">
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.75437 1.38428C4.72845 1.38405 4.70287 1.39026 4.67995 1.40237C4.65703 1.41447 4.63748 1.43209 4.62307 1.45364C4.60865 1.47519 4.59982 1.49998 4.59738 1.52579C4.59493 1.55159 4.59894 1.5776 4.60906 1.60147L4.6989 1.81358L6.28874 5.52062C6.29955 5.54583 6.3168 5.56775 6.33876 5.58419C6.36073 5.60062 6.38663 5.61098 6.41387 5.61422C6.44111 5.61747 6.46872 5.61349 6.49393 5.60268C6.51914 5.59187 6.54106 5.57462 6.55749 5.55265L9.49187 1.63428C9.50928 1.61107 9.51988 1.58347 9.52249 1.55457C9.52509 1.52567 9.5196 1.49661 9.50662 1.47066C9.49365 1.4447 9.4737 1.42288 9.44901 1.40762C9.42433 1.39236 9.39589 1.38428 9.36687 1.38428H4.75437Z" fill="var(--buttons_color)"/>
                                            <path d="M16.4034 2.40222L14.7909 6.16667C14.7807 6.19044 14.7766 6.21635 14.779 6.24208C14.7813 6.26782 14.79 6.29257 14.8042 6.31413C14.8184 6.33569 14.8378 6.35338 14.8606 6.36562C14.8833 6.37785 14.9088 6.38426 14.9346 6.38425H18.8799C18.9078 6.38426 18.9351 6.37683 18.9592 6.36271C18.9832 6.3486 19.003 6.32832 19.0165 6.30398C19.0301 6.27963 19.0369 6.25211 19.0362 6.22426C19.0355 6.19641 19.0274 6.16924 19.0127 6.14558L16.6795 2.38152C16.6645 2.35729 16.643 2.33764 16.6176 2.32472C16.5921 2.31181 16.5636 2.3061 16.5352 2.30824C16.5067 2.31037 16.4794 2.32026 16.4561 2.33683C16.4329 2.3534 16.4147 2.37601 16.4034 2.40222Z" fill="var(--buttons_color)"/>
                                            <path d="M3.32077 2.38313L0.988351 6.14563C0.973656 6.1693 0.965555 6.19647 0.964889 6.22432C0.964222 6.25217 0.971013 6.27969 0.984559 6.30403C0.998106 6.32838 1.01791 6.34866 1.04193 6.36277C1.06595 6.37688 1.09331 6.38432 1.12116 6.38431H5.06648C5.09233 6.38428 5.11778 6.37783 5.14053 6.36554C5.16328 6.35326 5.18263 6.33552 5.19684 6.31392C5.21105 6.29231 5.21967 6.26752 5.22194 6.24177C5.22421 6.21601 5.22005 6.19009 5.20984 6.16634L3.59421 2.40383C3.58266 2.37828 3.56443 2.3563 3.54146 2.34021C3.51848 2.32412 3.4916 2.31451 3.46363 2.3124C3.43566 2.31028 3.40764 2.31573 3.3825 2.32817C3.35737 2.34062 3.33604 2.3596 3.32077 2.38313V2.38313Z" fill="var(--buttons_color)"/>
                                            <path d="M15.3624 1.38428H10.462C10.4479 1.38419 10.434 1.38805 10.4219 1.39544C10.4099 1.40283 10.4001 1.41344 10.3938 1.42608C10.3874 1.43872 10.3848 1.45288 10.3861 1.46696C10.3873 1.48104 10.3925 1.49448 10.4011 1.50576L13.5229 5.66436C13.5309 5.67504 13.5416 5.68344 13.5538 5.68869C13.5661 5.69395 13.5795 5.69588 13.5927 5.6943C13.6059 5.69271 13.6185 5.68766 13.6292 5.67967C13.6398 5.67167 13.6482 5.661 13.6534 5.64874L15.2972 1.81397L15.43 1.48819C15.4347 1.477 15.4366 1.46485 15.4355 1.45278C15.4345 1.4407 15.4305 1.42905 15.424 1.41884C15.4175 1.40862 15.4085 1.40014 15.398 1.39413C15.3875 1.38811 15.3757 1.38473 15.3636 1.38428H15.3624Z" fill="var(--buttons_color)"/>
                                            <path d="M9.33616 17.6343L5.8428 7.73232C5.83254 7.70357 5.81362 7.6787 5.78865 7.66114C5.76368 7.64359 5.73387 7.6342 5.70334 7.63428H1.00413C0.976904 7.6342 0.950208 7.64177 0.927079 7.65613C0.90395 7.67048 0.885319 7.69105 0.873308 7.71547C0.861297 7.7399 0.856389 7.76722 0.859145 7.7943C0.8619 7.82138 0.872207 7.84714 0.888892 7.86865L9.32639 18.7987C9.35495 18.8357 9.3957 18.8614 9.4414 18.8712C9.48709 18.881 9.53478 18.8743 9.576 18.8522C9.61746 18.8304 9.64969 18.7944 9.66683 18.7509C9.68397 18.7073 9.68487 18.659 9.66936 18.6147L9.33616 17.6343Z" fill="var(--buttons_color)"/>
                                            <path d="M18.9956 7.63428H14.2964C14.266 7.63427 14.2364 7.6437 14.2116 7.66127C14.1868 7.67883 14.1681 7.70366 14.1581 7.73232L10.3222 18.5979C10.3061 18.6436 10.3067 18.6934 10.324 18.7386C10.3414 18.7838 10.3742 18.8213 10.4167 18.8444C10.4592 18.8674 10.5085 18.8746 10.5558 18.8647C10.6031 18.8547 10.6453 18.8283 10.6749 18.7901L19.1108 7.86865C19.1275 7.84714 19.1378 7.82138 19.1406 7.7943C19.1433 7.76722 19.1384 7.7399 19.1264 7.71547C19.1144 7.69105 19.0958 7.67048 19.0727 7.65613C19.0495 7.64177 19.0228 7.6342 18.9956 7.63428V7.63428Z" fill="var(--buttons_color)"/>
                                            <path d="M10.1251 3.21747L12.3126 6.13427C12.33 6.15748 12.3406 6.18508 12.3432 6.21398C12.3458 6.24288 12.3403 6.27194 12.3274 6.29789C12.3144 6.32385 12.2944 6.34567 12.2697 6.36093C12.2451 6.37618 12.2166 6.38426 12.1876 6.38426H7.8126C7.78358 6.38426 7.75514 6.37618 7.73046 6.36093C7.70577 6.34567 7.68582 6.32385 7.67285 6.29789C7.65987 6.27194 7.65438 6.24288 7.65698 6.21398C7.65959 6.18508 7.67019 6.15748 7.6876 6.13427L9.8751 3.21747C9.88966 3.19806 9.90853 3.18231 9.93022 3.17146C9.95192 3.16062 9.97584 3.15497 10.0001 3.15497C10.0244 3.15497 10.0483 3.16062 10.07 3.17146C10.0917 3.18231 10.1105 3.19806 10.1251 3.21747V3.21747ZM9.85166 15.339L7.20713 7.84247C7.19883 7.81891 7.19629 7.79371 7.19973 7.76897C7.20318 7.74423 7.2125 7.72068 7.22693 7.70029C7.24135 7.67989 7.26045 7.66326 7.28263 7.65177C7.30481 7.64028 7.32942 7.63428 7.3544 7.63426H12.6458C12.6708 7.63428 12.6954 7.64028 12.7176 7.65177C12.7397 7.66326 12.7589 7.67989 12.7733 7.70029C12.7877 7.72068 12.797 7.74423 12.8005 7.76897C12.8039 7.79371 12.8014 7.81891 12.7931 7.84247L10.1474 15.339C10.1366 15.3694 10.1166 15.3957 10.0903 15.4144C10.0639 15.433 10.0324 15.443 10.0001 15.443C9.96781 15.443 9.93632 15.433 9.90995 15.4144C9.88358 15.3957 9.86363 15.3694 9.85283 15.339H9.85166Z" fill="var(--buttons_color)"/>
                                        </svg>
                                    </div>
                                </div>            
                            </div>

                        </div>
                    </div>
                </div>
                <h1 v-if="list_winner_daily.length>0 && list_winner_general.length>0" class="col-12 alignVerticalEnd" style="font-weight: 300;font-size: 24px;color: var(--buttons_color);">{{ $t('ranking.ganhadores') }}</h1>
                <div class="ranking__winners flex flex-wrap  mobile-change">
                    <h1 v-if="list_winner_daily.length>0" class="col-12 alignVerticalStart" style="font-weight: 300;font-size: 21px;color: var(--buttons_color);">{{ $t('ranking.diario') }}</h1>


                    <div v-if="list_winner_daily.length>0" class="swiper-container ranking__winners--winner">
                        <div class="swiper-wrapper" style="display: flex;justify-content: space-around;">
                            <!-- for -->
                            <div v-for="(item,index) in list_winner_daily" :key="index+'winner_daily_mobile'" class="swiper-slide">
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <!-- <img src="../../public/assets/icons/ranking/my-friends.svg" alt="imagem de um dos seus amigos"> -->
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
<!--                                        <img v-else src="https://via.placeholder.com/300" alt="avatar">-->
                                    </div>
                                    
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">{{item.points}}</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">{{item.nickname}}</h3>
                                    </div>
                                    <h2 class="player-position" >{{item.date}}</h2>
                                </div>
                            <!-- <div v-for="(item,index) in list_winner_daily" :key="index+'winner_daily_mobile'" class="swiper-slide"> -->
                                <!-- <div class="box flex flex-wrap  ranking">
                                    <div class="box__imgAvatar">
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
                                     <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                    </div>

                                    <div class="alignVerticalStart col-12">
                                        <div class="alignVerticalStart col-12">
                                            <p class="col-12">{{ item ? item.nickname : '' }}</p>
                                            <h3>{{ item ? item.points : '' }}</h3> --> 
                                        <!-- </div> 
                                    </div>

                                    <div class="col-12 flex">
                                       <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-8 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">{{ item.points }}</p>
                                    </div>
                                    <div class="col-12 flex">
                                        <h2 class="player-position" >{{item.position}}º</h2>
                                    </div>
                                </div> -->
                            </div>
                            <!-- fim for -->

                            <!-- <div class="swiper-slide">
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <img src="../../public/assets/icons/ranking/my-friends.svg" alt="imagem de um dos seus amigos">
                                    </div>
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">1.000.000</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">Fernando Gomes</h3>
                                    </div>
                                    <h2 class="player-position" >3º</h2>
                                </div>
                            </div>  -->
                            <!-- <div class="swiper-slide">
                                <div class="card-ranking divulgation">
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation-white.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: #ffff;">---</p>
                                    </div>
                                    <div class="col-12 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: #ffff;max-width: 200px;">Divulgação dia <a href="#" style="color: #ffff;text-decoration: underline;"> ??/?? às ??:??h</a></h3>
                                    </div>
                                    <h2 class="player-position" style="color: #ffff" >?º</h2>
                                </div>
                            </div> -->

                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>

                <div  v-if="list_winner_general.length>0" class="ranking__winners flex flex-wrap  mobile-change">

                    <!-- <h1 class="col-12 alignVerticalEnd" style="font-weight: 300;font-size: 24px;color: var(--buttons_color);">Ganhadores</h1> -->

                    <h1 class="col-12 alignVerticalStart" style="font-weight: 300;font-size: 21px;color: var(--buttons_color);">{{ $t('ranking.geral') }}</h1>


                    <div class="swiper-container ranking__winners--winner">
                        <div class="swiper-wrapper" style="display: flex;justify-content: space-around;">
                            <!-- for -->
                            <div v-for="(item,index) in list_winner_general" :key="index+'winner_general_mobile'" class="swiper-slide">
                                <!-- <div class="box flex flex-wrap  ranking">
                                    <div class="box__imgAvatar">
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
                                        <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                    </div>
                                    <div class="col-12 flex">
                                       <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-8 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">1.000.000</p>
                                    </div>
                                    <div class="col-12 flex">
                                        <h2 class="player-position" >3º</h2>
                                    </div>
                                </div> -->
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <!-- <img src="../../public/assets/icons/ranking/my-friends.svg" alt="imagem de um dos seus amigos"> -->
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
<!--                                        <img v-else src="https://via.placeholder.com/300" alt="avatar">-->
                                    </div>
                                    
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">{{item.points}}</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">{{item.nickname}}</h3>
                                    </div>
                                    <h2 class="player-position" >{{item.position}}º</h2>
                                </div>
                            </div>
                            <!-- fim for -->

                            <!--
                            friend-list
                             <div class="swiper-slide">
                                <div class="card-ranking">
                                    <div class="box__imgAvatar">
                                        <img src="../../public/assets/icons/ranking/my-friends.svg" alt="imagem de um dos seus amigos">
                                    </div>
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: var(--buttons_color);">1.000.000</p>
                                    </div>
                                    <div class="col-8 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: var(--buttons_color);">Fernando Gomes</h3>
                                    </div>
                                    <h2 class="player-position" >3º</h2>
                                </div>
                            </div>  -->
<!-- divulgation date                            <div v-if="list_winner_general.length<=0 && !winner_general.divulgation" class="swiper-slide">
                                <div class="card-ranking divulgation">
                                    <div class="col-12 flex">
                                        <img src="../../public/assets/icons/ranking/premiation-white.svg" alt="imagem de premiação">
                                    </div>
                                    <div class="col-12 flex">
                                        <p style="font-size: 16px;text-transform: uppercase;color: #ffff;">-&#45;&#45;</p>
                                    </div>
                                    <div class="col-12 flex">
                                        <h3 style="font-weight: bold;font-size: 18px;text-transform: uppercase;color: #ffff;max-width: 200px;">{{ $t('ranking.divulgacaoDia') }} <a href="#" style="color: #ffff;text-decoration: underline;">{{ winner_general.date }} {{ $t('ranking.as') }} {{ winner_general.time }}h</a></h3>
                                    </div>
                                    &lt;!&ndash; <h2 class="player-position" style="color: #ffff" >?º</h2> &ndash;&gt;
                                </div>
                            </div>-->

                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="ranking">

            <div class="ranking__title">
                <h1>Ranking</h1>

                <div class="ranking__title--bnt flex">
                    <a v-on:click="tutorialHandler" class="alignVertical">
                        <div>Assistir ao </div>tutorial
                        <img :src="$url + 'assets/images/tickets/play.svg'">
                    </a>
                </div>
            </div>

            <div class="content d-none d-sm-block">
                <div class="ranking__imgHeader flex flex-wrap">
                    <div class="swiper-container ranking__imgHeader--swiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img :src="$url + 'assets/images/rankin/desktop/banner_ranking-1.png'">
                            </div>
                            <div class="swiper-slide">
                                <img :src="$url + 'assets/images/rankin/desktop/banner_ranking-2.png'">
                            </div>
                            <div class="swiper-slide">
                                <img :src="$url + 'assets/images/rankin/desktop/banner_ranking-3.png'">
                            </div>
                        </div>
                        <div class="swiper-button-next">
                            <svg width="137" height="28" viewBox="0 0 137 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 14L135.76 13.9996M121.284 1L135.328 14L121.284 27" stroke-width="2"/>
                            </svg>
                        </div>
                        <div class="swiper-button-prev">
                            <svg width="138" height="28" viewBox="0 0 138 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M137.76 14.0005L2.00008 14.0009M16.476 27.0005L2.43187 14.0005L16.476 1.0005" stroke-width="2"/>
                            </svg>
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
                </div>
                <p>
                    Consulte o Regulamento completo <a style="text-decoration: underline;" v-on:click="$app.modal_Termos = true">aqui</a> antes de participar. Período de Participação: 08/07/2021 a 23/07/2021. Certificado de Autorização SECAP nº 03.013681/2021.
                </p>
                <div v-if="Date.now() >= Date.parse('07/08/2021')" class="ranking__table flex flex-wrap">


                    <div class="ranking__table--title flex flex-wrap col-12">
                        <div v-if="false" class="col-6 flex flex-wrap">
                            <p class="alignVerticalStart col-12"><b>Posição do dia</b></p>

                        </div>
                        <div class="col-6 flex flex-wrap">
                            <p class="alignVerticalStart col-12"><b>posição geral</b></p>

                        </div>
                    </div>

                    <div v-if="false " class="ranking__table--table flex flex-wrap col-6">

                        <div class="ranking__table--table---voucher flex flex-wrap alignVertical col-12">
                            <div class="alignVertical col-12">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.8564 28.8627H11.1421M3.04688 4.48178V7.49749C3.04688 9.49773 3.79256 11.4266 5.1392 12.9099L7.90402 15.9552M28.9516 4.48178V7.49749C28.9516 9.49773 28.206 11.4266 26.8593 12.9099L24.0945 15.9552M7.90402 3.04761H24.0945V14.3417C24.0945 18.7971 20.4701 22.409 15.9993 22.409C11.5284 22.409 7.90402 18.7971 7.90402 14.3417V3.04761Z" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>

                            <div class="col-12">
                                <p>VALE COMPRAS R$ 1.000 <br>NA LOJA NBA</p>
                            </div>

                            <div class="col-12">
                                <label>Tempo restante</label>
                            </div>

                            <div class="col-12">
                                <p><b>{{ relogio_restante_dia }}</b></p>
                            </div>

                            <div class="col-12">
                                <br>
                                <p><b style="font-size: 15px">Atualizado às {{ timestamp_daily }}. <br>O ranking é publicado a cada 30 minutos.</b></p>
                            </div>
                        </div>

                        <div v-if="my_daily_ranking" class="ranking__table--table---row rowRed alignVertical col-12">
                            <div class="colocacao alignVertical">
                                {{ my_daily_ranking }}º
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ nickname }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ my_daily_point }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>

                        <div v-for="(item, index) in list_daily" :key="index"
                             :class="'ranking__table--table---row alignVertical col-12'+(index<1?' rowBlue':'')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div class="avatar alignVertical">
                                <img :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.daily_point : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>

                    <div class="ranking__table--table flex flex-wrap col-6" style="width: 100%;max-width: 100%">
                        <div class="ranking__table--table---voucher flex flex-wrap alignVertical col-12">
                            <div class="alignVertical col-12">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.8564 28.8627H11.1421M3.04688 4.48178V7.49749C3.04688 9.49773 3.79256 11.4266 5.1392 12.9099L7.90402 15.9552M28.9516 4.48178V7.49749C28.9516 9.49773 28.206 11.4266 26.8593 12.9099L24.0945 15.9552M7.90402 3.04761H24.0945V14.3417C24.0945 18.7971 20.4701 22.409 15.9993 22.409C11.5284 22.409 7.90402 18.7971 7.90402 14.3417V3.04761Z" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>

                            <div class="col-12">
                                <p>VALE COMPRAS PARA OS 5 PRIMEIROS COLOCADOS + KIT EXCLUSIVO NBA</p>
                            </div>

                            <div class="col-12">
                                <label>Tempo restante</label>
                            </div>

                            <div class="col-12">
                                <p><b>{{ relogio_restante_geral }}</b></p>
                            </div>
                            <div class="col-12">
                                <br>
                                <p><b style="font-size: 15px">Atualizado às {{ timestamp_general }}. <br>O ranking é publicado a cada 30 minutos.</b></p>
                            </div>
                        </div>
                        <div v-if="my_general_ranking" class="ranking__table--table---row rowRed alignVertical col-12">
                            <div class="colocacao alignVertical">
                                {{ my_general_ranking }}º
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ nickname }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ my_point }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                        <div v-for="(item, index) in list_general" :key="index"
                             :class="'ranking__table--table---row alignVertical col-12'+(index<5?' rowBlue':'')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div class="avatar alignVertical">
                                <img
                                    :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                    :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.total_points : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>

                </div>

                <div v-if="Date.now() >= Date.parse('07/08/2021')" class="ranking__entreAmigos flex flex-wrap">
                    <div class="alignVertical col-12">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.9991 36.1905C28.9408 36.1905 36.1895 28.9418 36.1895 20C36.1895 11.0583 28.9408 3.80957 19.9991 3.80957C11.0573 3.80957 3.80859 11.0583 3.80859 20C3.80859 28.9418 11.0573 36.1905 19.9991 36.1905Z" stroke="#D91A32" stroke-width="2" stroke-linecap="square"/>
                            <path d="M3.80859 17.1428C21.3868 4.28561 36.1895 17.1428 36.1895 17.1428" stroke="#D91A32" stroke-width="2"/>
                            <path d="M36.1914 22.8572C18.6132 35.7144 3.81046 22.8572 3.81046 22.8572" stroke="#D91A32" stroke-width="2"/>
                        </svg>
                    </div>

                    <div class="ranking__entreAmigos--title flex flex-wrap col-12">
                        <div class="col-4 alignVerticalStart">

                            <p><b>Posição geral</b></p>
                        </div>
                        <div class="col-4 alignVertical">
                            <p>Entre amigos</p>
                        </div>
                        <div class="col-4 alignVerticalEnd">

                            <p><b></b></p>
                        </div>
                    </div>

                    <div v-if="false" class="ranking__entreAmigos--table flex flex-wrap col-6">
                        <div v-for="(item, index) in list_daily_friends" :key="index"
                             :class="'ranking__entreAmigos--table---row alignVertical col-12'+((item && item.i)?' rowRed':' rowBlue')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div v-if="(item && !item.i)" class="avatar alignVertical">
                                <img :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                     :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.points : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>



                    <div class="ranking__entreAmigos--table flex flex-wrap col-6" style="width: 100%;max-width: 100%">
                        <div v-for="(item, index) in list_general_friends" :key="index"
                             :class="'ranking__entreAmigos--table---row alignVertical col-12'+((item && item.i)?' rowRed':' rowBlue')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div v-if="(item && !item.i)" class="avatar alignVertical">
                                <img
                                    :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                    :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.points : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>
                </div>



            <div :style="(list_winner_daily==null || list_winner_daily.length==0)?'display: none!important':''" class="ranking__ganhadores flex flex-wrap d-none d-sm-flex">
                <div class="ranking__ganhadores--title flex flex-wrap col-12" style="width: 100%;">
                    <div class="col-6 alignVerticalStart" style="align-items: flex-end; padding-bottom: 10px;">
                        <p>Ranking Diário</p>
                    </div>
                    <div class="col-6 alignVerticalEnd">
                        <p><b>Ganhadores</b></p>
                    </div>
                </div>

                <div class="swiper-container ranking__ganhadores--swiper" style="width: 100%;">

                    <div class="bgGanhadores">
                        <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                    </div>

                    <div class="swiper-wrapper">
                        <div v-for="(item,index) in list_winner_daily" :key="index+'winner_daily'" class="swiper-slide">
                            <div class="alignVertical" style="position: relative;">
                                <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                </svg>

                                <div class="card">
                                    <div class="card__avatar">
                                        <img v-if="item.avatar"
                                             :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                             :src="item.avatar" alt="avatar">
                                       <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                    </div>

                                    <div class="alignVerticalStart col-12">
                                        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                        </svg>
                                    </div>

                                    <div class="alignVerticalStart col-12">
                                        <div class="alignVerticalStart col-12">
                                            <p class="col-12">{{ item ? item.nickname : '' }}</p>
                                            <h3>{{ item ? item.points : '' }}</h3>
                                        </div>
                                    </div>

                                    <div class="card__footer alignVertical col-12">
                                        <div class="alignVerticalStart col-8">
                                            <hr>
                                        </div>
                                        <div class="alignVerticalEnd col-4">
                                            <p>{{ item ? item.date : '' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-button-next">
                        <svg width="137" height="28" viewBox="0 0 137 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 14L135.76 13.9996M121.284 1L135.328 14L121.284 27" stroke-width="2"/>
                        </svg>
                    </div>
                    <div class="swiper-button-prev">
                        <svg width="138" height="28" viewBox="0 0 138 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M137.76 14.0005L2.00008 14.0009M16.476 27.0005L2.43187 14.0005L16.476 1.0005" stroke-width="2"/>
                        </svg>
                    </div>
                    <div class="swiper-pagination"></div>
                </div>

            </div>

            <div v-if="winner_general != null" class="ranking__ganhadoresGerais flex flex-wrap d-none d-sm-flex">

                <div class="ranking__ganhadoresGerais--title flex flex-wrap col-12">
                    <div class="col-12 alignVerticalBetween">
                        <p>Ranking Geral </p> <b v-if="winner_general !=  null && !winner_general.divulgation">DIVULGAÇÃO {{ winner_general.date }} às {{ winner_general.time }}h</b>
                    </div>
                </div>

                <div v-if="winner_general !=  null && winner_general.divulgation" class="ranking__ganhadoresGerais--cards flex ">
                    <div class="bgGanhadores">
                        <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                    </div>

                    <div v-for="(item,index) in winner_general.winner" :key="index+'winner_general'" class="ranking__ganhadoresGerais--cards---card alignVertical" style="align-content: center;">
                        <div class="alignVertical" style="position: relative; align-content: center;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>

                            <div class="card">
                                <div class="card__avatar">
                                    <img v-if="item.avatar"
                                         :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                         :src="item.avatar" alt="avatar">
                            <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                </div>

                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>

                                <div class="alignVerticalStart col-12">
                                    <div class="alignVerticalStart col-12">
                                        <p class="col-12">{{ item ? item.nickname : '' }}</p>
                                        <h3>{{ item ? item.points : '' }}</h3>
                                    </div>
                                </div>

                                <div class="card__footer alignVertical col-12">
                                    <div class="alignVerticalStart col-8">
                                        <hr>
                                    </div>
                                    <div class="alignVerticalEnd col-4">
                                        <h3>{{item ? item.position : ''}}º</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="ranking__ganhadoresGerais--cards flex p-0">
                    <div class="bgGanhadores">
                        <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                    </div>
                    <div v-if="winner_general != null" class="ranking__ganhadoresGerais--cards---cardRed">
                        <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>
                            <div class="card">
                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>
                                <div class="alignVerticalStart col-12">

                                    <div class="alignVerticalStart col-12">
                                        <p>1º lugar</p>
                                        <p><b style="font-size: 20px;">Voucher NBA R$5.0000,00</b></p>
                                        <p>+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="winner_general != null" class="ranking__ganhadoresGerais--cards---cardRed">
                        <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>
                            <div class="card">
                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>
                                <div class="alignVerticalStart col-12">

                                    <div class="alignVerticalStart col-12">
                                        <p>2º lugar</p>
                                        <p><b style="font-size: 20px;">Voucher NBA R$4.0000,00</b></p>
                                        <p>+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="winner_general != null" class="ranking__ganhadoresGerais--cards---cardRed">

                        <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>
                            <div class="card">
                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>
                                <div class="alignVerticalStart col-12">

                                    <div class="alignVerticalStart col-12">
                                        <p>3º lugar</p>
                                        <p><b style="font-size: 20px;">Voucher NBA R$3.0000,00</b></p>
                                        <p>+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="winner_general != null" class="ranking__ganhadoresGerais--cards---cardRed">
                        <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>
                            <div class="card">
                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>
                                <div class="alignVerticalStart col-12">

                                    <div class="alignVerticalStart col-12">
                                        <p>4º lugar</p>
                                        <p><b style="font-size: 20px;">Voucher NBA R$2.0000,00</b></p>
                                        <p>+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="winner_general != null" class="ranking__ganhadoresGerais--cards---cardRed">
                        <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                            <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                            </svg>
                            <div class="card">
                                <div class="alignVerticalStart col-12">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                    </svg>
                                </div>
                                <div class="alignVerticalStart col-12">

                                    <div class="alignVerticalStart col-12">
                                        <p>5º lugar</p>
                                        <p><b style="font-size: 20px;">Voucher NBA R$1.0000,00</b></p>
                                        <p>+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p style="max-width: 1000px;" class="ranking__ganhadoresGerais--cards"><strong>Kit NBA:</strong> 1 Mini Tabela Basketball, 1 Cartão Presente R$ 200,00 Loja NBA, 1 camiseta masc. Nike Branca,
                    1 camiseta masc. Nike Preta, 1 Voucher NBA League pass anual, 2 Porta-copos, 1 Squeeze, 1 caneca, 1 Copo de 550ml, 2 pares de meia Subway, 1 Vale compras R$ 100 Nescau.</p>




                <div class="info-pontuacao">
                    <div class="ranking__entreAmigos--title col-4 alignVerticalStart">
                        <p><b>Pontuação</b></p>
                    </div>
                    <table>
                        <tbody><tr>
                            <td><center><b>Atividade</b></center></td>
                            <td><center><b>Periodicidade da Pontuação	</b></center></td>
                            <td><center><b>Quantidade de Pontos de Cada Atividade 	</b></center></td>
                            <td><center><b>Total de Pontos Acumulados Diariamente</b></center></td>
                        </tr>
                        <tr>
                            <td><center>Entrar no Jogo	</center></td>
                            <td><center>1 vez por dia	</center></td>
                            <td><center>200 pontos	</center></td>
                            <td><center>200 moedas</center></td>
                        </tr>
                        <tr>
                            <td><center>Entrar nos Ambientes (total de 9 disponíveis)	</center></td>
                            <td><center>Até 9 vezes por dia	</center></td>
                            <td><center>50 pontos	</center></td>
                            <td><center>450 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Play Vídeos (qualquer vídeo - live, on demand)	</center></td>
                            <td><center>Até 10 vezes por dia	</center></td>
                            <td><center>50 pontos	</center></td>
                            <td><center>500 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Coletar Item Patrocinador e Responder Corretamente o Quiz	</center></td>
                            <td><center>Até 30 vezes por dia	</center></td>
                            <td><center>60 pontos	</center></td>
                            <td><center>1.800 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Cesta Pop a Shot (acertar a cesta jogo single ou multiplayer)	</center></td>
                            <td><center>Até 200 vezes por dia	</center></td>
                            <td><center>15 pontos	</center></td>
                            <td><center>3.000 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Partida Pop a Shot (ganhar partida multiplayer)</center></td>
                            <td><center>Até 5 vezes por dia	</center></td>
                            <td><center>100 pontos	</center></td>
                            <td><center>500 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Tirar Foto da Tela via Plataforma</center></td>
                            <td><center>1 vez por dia	</center></td>
                            <td><center>150 pontos	</center></td>
                            <td><center>150 pontos</center></td>
                        </tr>
                        <tr>
                            <td><center>Trivia Museu (cada resposta certa)</center></td>
                            <td><center>Até 100 vezes durante toda a promoção	</center></td>
                            <td><center>30 pontos	</center></td>
                            <td><center>3000 pontos</center></td>
                        </tr>
                    </tbody></table>
                </div>
            </div>



            </div>

            <div class="content--mobile">
                <div class="ranking__button-tabs">
                    <button v-if="false" :class="{'active': active_tab == 'dia'}" v-on:click="changeTabRanking('dia')">Dia</button>
                    <button v-if="Date.now() >= Date.parse('07/08/2021')" :class="{'active': active_tab == 'geral'}" v-on:click="changeTabRanking('geral')">Geral</button>
                    <button :class="{'active': active_tab == 'ganhadores'}" v-on:click="changeTabRanking('ganhadores')">Ganhadores</button>
                </div>

                <div v-if="false" v-show="active_tab == 'dia'">

                    <div class="mb-4" style="height: 170px">
                        <img class="banner-tab" :src="$url + 'assets/images/rankin/banner-daily-tab.png'">
                    </div>

                    <div class="px-4">
                        <div class="ranking__table--table---voucher flex flex-wrap alignVertical">
                            <div class="alignVertical col-12">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.8564 28.8627H11.1421M3.04688 4.48178V7.49749C3.04688 9.49773 3.79256 11.4266 5.1392 12.9099L7.90402 15.9552M28.9516 4.48178V7.49749C28.9516 9.49773 28.206 11.4266 26.8593 12.9099L24.0945 15.9552M7.90402 3.04761H24.0945V14.3417C24.0945 18.7971 20.4701 22.409 15.9993 22.409C11.5284 22.409 7.90402 18.7971 7.90402 14.3417V3.04761Z" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>

                            <div class="col-12">
                                <p>VALE COMPRAS R$ 1.000 NA LOJA NBA</p>
                            </div>

                            <div class="col-12">
                                <label>Tempo restante</label>
                            </div>

                            <div class="col-12">
                                <p><b>{{ relogio_restante_dia }}</b></p>
                            </div>

                            <div class="col-12">
                                <br>
                                <p><b style="font-size: 15px">Atualizado às {{ timestamp_daily }}. <br>O ranking é publicado a cada 30 minutos.</b></p>
                            </div>
                        </div>
                    </div>

                    <div class="px-4 mb-4">
                        <div v-if="my_daily_ranking" class="ranking__table--table---row rowRed alignVertical col-12">
                            <div class="colocacao alignVertical">
                                {{ my_daily_ranking }}º
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ nickname }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ my_daily_point }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>

                        <div v-for="(item, index) in list_daily" :key="index" :class="'ranking__table--table---row alignVertical col-12'+(index<1?' rowBlue':'')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div class="avatar alignVertical">
                                <img
                                    :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                    :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.daily_point : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>

                    <div class="px-4">
                        <div class="ranking__entreAmigos--title">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9991 36.1905C28.9408 36.1905 36.1895 28.9418 36.1895 20C36.1895 11.0583 28.9408 3.80957 19.9991 3.80957C11.0573 3.80957 3.80859 11.0583 3.80859 20C3.80859 28.9418 11.0573 36.1905 19.9991 36.1905Z" stroke="#D91A32" stroke-width="2" stroke-linecap="square"/>
                                <path d="M3.80859 17.1428C21.3868 4.28561 36.1895 17.1428 36.1895 17.1428" stroke="#D91A32" stroke-width="2"/>
                                <path d="M36.1914 22.8572C18.6132 35.7144 3.81046 22.8572 3.81046 22.8572" stroke="#D91A32" stroke-width="2"/>
                            </svg>

                            <p>Entre amigos</p>
                        </div>

                        <div class="ranking__entreAmigos--table flex flex-wrap">
                            <div v-for="(item, index) in list_daily_friends" :key="index"
                                 :class="'ranking__entreAmigos--table---row alignVertical col-12'+((item && item.i)?' rowRed':' rowBlue')">
                                <div class="colocacao alignVertical">
                                    {{ item ? item.ranking : '' }}º
                                </div>
                                <div v-if="(item && !item.i)" class="avatar alignVertical">
                                    <img
                                        :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                        :src="item.thumb">
                                </div>
                                <div class="name alignVerticalStart col-4">
                                    {{ item ? item.nickname : '' }}
                                </div>
                                <div class="points alignVerticalEnd col-4">
                                    {{ item ? item.points : '' }}
                                </div>
                                <div class="alignVertical col-1">
                                    <img :src="$url + 'assets/icons/header/moeda1.png'">
                                </div>
                            </div>
                        </div>

                        <div class="ranking__entreAmigos--title info-pontuacao" style="font-size:12px;text-align:left">
                            <div class="col-12 alignVerticalStart">
                                <p><b>Pontuação</b></p>
                            </div>
                            <table>
                                <tbody><tr>
                                    <td><center><b>Atividade</b></center></td>
                                    <td><center><b>Periodicidade da Pontuação	</b></center></td>
                                    <td><center><b>Quantidade de Pontos de Cada Atividade 	</b></center></td>
                                    <td><center><b>Total de Pontos Acumulados Diariamente</b></center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar no Jogo	</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>200 pontos	</center></td>
                                    <td><center>200 moedas</center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar nos Ambientes (total de 9 disponíveis)	</center></td>
                                    <td><center>Até 9 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>450 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Play Vídeos (qualquer vídeo - live, on demand)	</center></td>
                                    <td><center>Até 10 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Coletar Item Patrocinador e Responder Corretamente o Quiz	</center></td>
                                    <td><center>Até 30 vezes por dia	</center></td>
                                    <td><center>60 pontos	</center></td>
                                    <td><center>1.800 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Cesta Pop a Shot (acertar a cesta jogo single ou multiplayer)	</center></td>
                                    <td><center>Até 200 vezes por dia	</center></td>
                                    <td><center>15 pontos	</center></td>
                                    <td><center>3.000 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Partida Pop a Shot (ganhar partida multiplayer)</center></td>
                                    <td><center>Até 5 vezes por dia	</center></td>
                                    <td><center>100 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Tirar Foto da Tela via Plataforma</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>150 pontos	</center></td>
                                    <td><center>150 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Trivia Museu (cada resposta certa)</center></td>
                                    <td><center>Até 100 vezes durante toda a promoção	</center></td>
                                    <td><center>30 pontos	</center></td>
                                    <td><center>3000 pontos</center></td>
                                </tr>
                            </tbody></table>
                        </div>




                    </div>
                </div>

                <div v-if="Date.now() >= Date.parse('07/08/2021')" v-show="active_tab == 'geral'">

                    <div class="mb-4" style="height: 170px">
                        <img class="banner-tab" :src="$url + 'assets/images/rankin/banner-general-tab.png'">
                    </div>

                    <div class="px-4">
                        <div class="ranking__table--table---voucher flex flex-wrap alignVertical col-12">
                            <div class="alignVertical col-12">
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.8564 28.8627H11.1421M3.04688 4.48178V7.49749C3.04688 9.49773 3.79256 11.4266 5.1392 12.9099L7.90402 15.9552M28.9516 4.48178V7.49749C28.9516 9.49773 28.206 11.4266 26.8593 12.9099L24.0945 15.9552M7.90402 3.04761H24.0945V14.3417C24.0945 18.7971 20.4701 22.409 15.9993 22.409C11.5284 22.409 7.90402 18.7971 7.90402 14.3417V3.04761Z" stroke="white" stroke-width="2"/>
                                </svg>
                            </div>

                            <div class="col-12">
                                <p>VALE COMPRAS PARA OS 5 PRIMEIROS COLOCADOS + KIT EXCLUSIVO NBA</p>
                            </div>

                            <div class="col-12">
                                <label>Tempo restante</label>
                            </div>

                            <div class="col-12">
                                <p><b>{{ relogio_restante_geral }}</b></p>
                            </div>

                            <div class="col-12">
                                <br>
                                <p><b style="font-size: 15px">Atualizado às {{ timestamp_general }}. <br>O ranking é publicado a cada 30 minutos.</b></p>
                            </div>
                        </div>
                    </div>

                    <div class="px-4 mb-4">
                        <div v-if="my_general_ranking" class="ranking__table--table---row rowRed alignVertical col-12">
                            <div class="colocacao alignVertical">
                                {{ my_general_ranking }}º
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ nickname }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ my_point }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>

                        <div v-for="(item, index) in list_general" :key="index"
                             :class="'ranking__table--table---row alignVertical col-12'+(index<5?' rowBlue':'')">
                            <div class="colocacao alignVertical">
                                {{ item ? item.ranking : '' }}º
                            </div>
                            <div class="avatar alignVertical">
                                <img
                                    :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                    :src="item.thumb">
                            </div>
                            <div class="name alignVerticalStart col-4">
                                {{ item ? item.nickname : '' }}
                            </div>
                            <div class="points alignVerticalEnd col-4">
                                {{ item ? item.total_points : '' }}
                            </div>
                            <div class="alignVertical col-1">
                                <img :src="$url + 'assets/icons/header/moeda1.png'">
                            </div>
                        </div>
                    </div>

                    <div class="px-4">
                        <div class="ranking__entreAmigos--title">
                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.9991 36.1905C28.9408 36.1905 36.1895 28.9418 36.1895 20C36.1895 11.0583 28.9408 3.80957 19.9991 3.80957C11.0573 3.80957 3.80859 11.0583 3.80859 20C3.80859 28.9418 11.0573 36.1905 19.9991 36.1905Z" stroke="#D91A32" stroke-width="2" stroke-linecap="square"/>
                                <path d="M3.80859 17.1428C21.3868 4.28561 36.1895 17.1428 36.1895 17.1428" stroke="#D91A32" stroke-width="2"/>
                                <path d="M36.1914 22.8572C18.6132 35.7144 3.81046 22.8572 3.81046 22.8572" stroke="#D91A32" stroke-width="2"/>
                            </svg>

                            <p>Entre amigos</p>
                        </div>

                        <div class="ranking__entreAmigos--table flex flex-wrap">
                            <div v-for="(item, index) in list_general_friends" :key="index"
                                 :class="'ranking__entreAmigos--table---row alignVertical col-12'+(item.i?' rowRed':' rowBlue')">
                                <div class="colocacao alignVertical">
                                    {{ item ? item.ranking : '' }}º
                                </div>
                                <div v-if="(item && !item.i)" class="avatar alignVertical">
                                    <img
                                        :style="item.thumb.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                        :src="item.thumb">
                                </div>
                                <div class="name alignVerticalStart col-4">
                                    {{ item ? item.nickname : '' }}
                                </div>
                                <div class="points alignVerticalEnd col-4">
                                    {{ item ? item.points : '' }}
                                </div>
                                <div class="alignVertical col-1">
                                    <img :src="$url + 'assets/icons/header/moeda1.png'">
                                </div>
                            </div>
                        </div>

                        <div class="ranking__entreAmigos--title info-pontuacao" style="font-size:12px;text-align:left">
                            <div class="col-12 alignVerticalStart">
                                <p><b>Pontuação</b></p>
                            </div>
                            <table>
                                <tbody><tr>
                                    <td><center><b>Atividade</b></center></td>
                                    <td><center><b>Periodicidade da Pontuação	</b></center></td>
                                    <td><center><b>Quantidade de Pontos de Cada Atividade 	</b></center></td>
                                    <td><center><b>Total de Pontos Acumulados Diariamente</b></center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar no Jogo	</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>200 pontos	</center></td>
                                    <td><center>200 moedas</center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar nos Ambientes (total de 9 disponíveis)	</center></td>
                                    <td><center>Até 9 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>450 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Play Vídeos (qualquer vídeo - live, on demand)	</center></td>
                                    <td><center>Até 10 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Coletar Item Patrocinador e Responder Corretamente o Quiz	</center></td>
                                    <td><center>Até 30 vezes por dia	</center></td>
                                    <td><center>60 pontos	</center></td>
                                    <td><center>1.800 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Cesta Pop a Shot (acertar a cesta jogo single ou multiplayer)	</center></td>
                                    <td><center>Até 200 vezes por dia	</center></td>
                                    <td><center>15 pontos	</center></td>
                                    <td><center>3.000 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Partida Pop a Shot (ganhar partida multiplayer)</center></td>
                                    <td><center>Até 5 vezes por dia	</center></td>
                                    <td><center>100 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Tirar Foto da Tela via Plataforma</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>150 pontos	</center></td>
                                    <td><center>150 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Trivia Museu (cada resposta certa)</center></td>
                                    <td><center>Até 100 vezes durante toda a promoção	</center></td>
                                    <td><center>30 pontos	</center></td>
                                    <td><center>3000 pontos</center></td>
                                </tr>
                            </tbody></table>
                        </div>
                    </div>

                </div>

                <div :style="active_tab == 'ganhadores'?'':'display:none'">
                    <div :style="(list_winner_daily==null || list_winner_daily.length==0)?'display: none':''" class="ranking__ganhadores flex flex-wrap mt-3">
                        <div class="ranking__ganhadores--title flex flex-wrap col-12">
                            <div class="col-6 alignVerticalStart" style="align-items: flex-end; padding-bottom: 10px;">
                                <p>Ranking Diário</p>
                            </div>
                        </div>

                        <div class="swiper-container ranking__ganhadores--swiper mobile">

                            <div class="bgGanhadores">
                                <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                            </div>

                            <div class="swiper-wrapper">
                                <div v-for="(item,index) in list_winner_daily" :key="index+'winner_daily_mobile'" class="swiper-slide">
                                    <div class="alignVertical" style="position: relative;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>

                                        <div class="card">
                                            <div class="card__avatar">
                                                <img v-if="item.avatar"
                                                     :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                                     :src="item.avatar" alt="avatar">
                                               <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                            </div>

                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>

                                            <div class="alignVerticalStart col-12">
                                                <div class="alignVerticalStart col-12">
                                                    <p class="col-12">{{ item ? item.nickname : '' }}</p>
                                                    <h3>{{ item ? item.points : '' }}</h3>
                                                </div>
                                            </div>

                                            <div class="card__footer alignVertical col-12">
                                                <div class="alignVerticalStart col-8">
                                                    <hr>
                                                </div>
                                                <div class="alignVerticalEnd col-4">
                                                    <p>{{ item.date }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next">
                                <svg width="137" height="28" viewBox="0 0 137 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 14L135.76 13.9996M121.284 1L135.328 14L121.284 27" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-button-prev">
                                <svg width="138" height="28" viewBox="0 0 138 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M137.76 14.0005L2.00008 14.0009M16.476 27.0005L2.43187 14.0005L16.476 1.0005" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>

                    <div class="ranking__ganhadoresGerais flex flex-wrap">

                        <div class="ranking__ganhadoresGerais--title flex flex-wrap col-12">
                            <div class="col-12 alignVerticalStart">
                                <p>Ranking Geral</p>
                                <b v-if="winner_general !=  null && !winner_general.divulgation">DIVULGAÇÃO {{ winner_general.date }} às {{ winner_general.time }}h</b>
                            </div>
                        </div>
                        <div v-if="winner_general != null && !winner_general.divulgation" class="swiper-container ranking__ganhadores--swiper mobile">
                            <div class="bgGanhadores">
                                <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                            </div>
                            <div class="swiper-wrapper">
                                <div v-if="winner_general != null" class="swiper-slide ranking__ganhadoresGerais--cards---cardRed ">
                                    <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>
                                        <div class="card">
                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="alignVerticalStart col-12">

                                                <div class="alignVerticalStart col-12">
                                                    <p style="font-size: 16px;">1º lugar</p>
                                                    <p><b style="font-size: 16px;">Voucher NBA R$5.0000,00</b></p>
                                                    <p style="font-size: 16px;margin-top:0;">+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="winner_general != null" class="swiper-slide ranking__ganhadoresGerais--cards---cardRed ">
                                    <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>
                                        <div class="card">
                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="alignVerticalStart col-12">

                                                <div class="alignVerticalStart col-12">
                                                    <p style="font-size: 16px;">2º lugar</p>
                                                    <p><b style="font-size: 16px;">Voucher NBA R$4.0000,00</b></p>
                                                    <p style="font-size: 16px;margin-top:0;">+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="winner_general != null" class="swiper-slide ranking__ganhadoresGerais--cards---cardRed ">

                                    <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>
                                        <div class="card">
                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="alignVerticalStart col-12">

                                                <div class="alignVerticalStart col-12">
                                                    <p style="font-size: 16px;">3º lugar</p>
                                                    <p><b style="font-size: 16px;">Voucher NBA R$3.0000,00</b></p>
                                                    <p style="font-size: 16px;margin-top:0;">+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="winner_general != null" class="swiper-slide ranking__ganhadoresGerais--cards---cardRed ">
                                    <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>
                                        <div class="card">
                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="alignVerticalStart col-12">

                                                <div class="alignVerticalStart col-12">
                                                    <p style="font-size: 16px;">4º lugar</p>
                                                    <p><b style="font-size: 16px;">Voucher NBA R$2.0000,00</b></p>
                                                    <p style="font-size: 16px;margin-top:0;">+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="winner_general != null" class="swiper-slide ranking__ganhadoresGerais--cards---cardRed ">
                                    <div class="alignVertical" style="position: relative; background-color: #D91A32;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>
                                        <div class="card">
                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>
                                            <div class="alignVerticalStart col-12">

                                                <div class="alignVerticalStart col-12">
                                                    <p style="font-size: 16px;">5º lugar</p>
                                                    <p><b style="font-size: 16px;">Voucher NBA R$1.0000,00</b></p>
                                                    <p style="font-size: 16px;margin-top:0;">+ KIT NBA<br>+VALE COMPRAS<br> R$ 100 NESCAU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next">
                                <svg width="137" height="28" viewBox="0 0 137 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 14L135.76 13.9996M121.284 1L135.328 14L121.284 27" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-button-prev">
                                <svg width="138" height="28" viewBox="0 0 138 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M137.76 14.0005L2.00008 14.0009M16.476 27.0005L2.43187 14.0005L16.476 1.0005" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-pagination"></div>

                        </div>
                        <div v-else class="swiper-container ranking__ganhadores--swiper mobile">
                            <div class="bgGanhadores">
                                <img :src="$url + 'assets/images/rankin/bgganhadores.png'">
                            </div>
                            <div class="swiper-wrapper">
                                <div v-for="(item,index) in winner_general.winner" :key="index+'winner_general'" class="swiper-slide" >
                                    <div class="alignVertical" style="position: relative;">
                                        <svg width="191" height="213" viewBox="0 0 191 213" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.13876 0H191V213L0 203L9.13876 0Z" />
                                        </svg>

                                        <div class="card">
                                            <div class="card__avatar">
                                                <img v-if="item.avatar"
                                                     :style="item.avatar.indexOf('ui-avatars.com')===-1?'object-position: 0px;':''"
                                                     :src="item.avatar" alt="avatar">
                                                <img v-else src="https://via.placeholder.com/300" alt="avatar">
                                            </div>

                                            <div class="alignVerticalStart col-12">
                                                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19.5539 27.0589H10.4467M2.85742 4.20172V7.02894C2.85742 8.90417 3.5565 10.7125 4.81898 12.1031L7.41099 14.958M27.1431 4.20172V7.02894C27.1431 8.90417 26.4441 10.7125 25.1816 12.1031L22.5896 14.958M7.41099 2.85718H22.5896V13.4454C22.5896 17.6224 19.1917 21.0084 15.0003 21.0084C10.8088 21.0084 7.41099 17.6224 7.41099 13.4454V2.85718Z" stroke="white" stroke-width="2"/>
                                                </svg>
                                            </div>

                                            <div class="alignVerticalStart col-12">
                                                <div class="alignVerticalStart col-12">
                                                    <p class="col-12">{{ item ? item.nickname : '' }}</p>
                                                    <h3>{{ item ? item.points : '' }}</h3>
                                                </div>
                                            </div>

                                            <div class="card__footer alignVertical col-12">
                                                <div class="alignVerticalStart col-8">
                                                    <hr>
                                                </div>
                                                <div class="alignVerticalEnd col-4">
                                                    <h3>{{item ? item.position : ''}}º</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-button-next">
                                <svg width="137" height="28" viewBox="0 0 137 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 14L135.76 13.9996M121.284 1L135.328 14L121.284 27" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-button-prev">
                                <svg width="138" height="28" viewBox="0 0 138 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M137.76 14.0005L2.00008 14.0009M16.476 27.0005L2.43187 14.0005L16.476 1.0005" stroke-width="2"/>
                                </svg>
                            </div>
                            <div class="swiper-pagination"></div>
                        </div>
                        <p style="max-width: 80%;" class="ranking__ganhadoresGerais--cards"><strong>Kit NBA:</strong> 1 Mini Tabela Basketball, 1 Cartão Presente R$ 200,00 Loja NBA, 1 camiseta masc. Nike Branca,
                            1 camiseta masc. Nike Preta, 1 Voucher NBA League pass anual, 2 Porta-copos, 1 Squeeze, 1 caneca, 1 Copo de 550ml, 2 pares de meia Subway, 1 Vale compras R$ 100 Nescau.</p>

                        <div class="ranking__entreAmigos--title info-pontuacao" style="font-size:12px;text-align:left;margin:0 20px;">
                            <div class="col-12 alignVerticalStart">
                                <p><b>Pontuação</b></p>
                            </div>
                            <table>
                                <tbody><tr>
                                    <td><center><b>Atividade</b></center></td>
                                    <td><center><b>Periodicidade da Pontuação	</b></center></td>
                                    <td><center><b>Quantidade de Pontos de Cada Atividade 	</b></center></td>
                                    <td><center><b>Total de Pontos Acumulados Diariamente</b></center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar no Jogo	</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>200 pontos	</center></td>
                                    <td><center>200 moedas</center></td>
                                </tr>
                                <tr>
                                    <td><center>Entrar nos Ambientes (total de 9 disponíveis)	</center></td>
                                    <td><center>Até 9 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>450 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Play Vídeos (qualquer vídeo - live, on demand)	</center></td>
                                    <td><center>Até 10 vezes por dia	</center></td>
                                    <td><center>50 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Coletar Item Patrocinador e Responder Corretamente o Quiz	</center></td>
                                    <td><center>Até 30 vezes por dia	</center></td>
                                    <td><center>60 pontos	</center></td>
                                    <td><center>1.800 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Cesta Pop a Shot (acertar a cesta jogo single ou multiplayer)	</center></td>
                                    <td><center>Até 200 vezes por dia	</center></td>
                                    <td><center>15 pontos	</center></td>
                                    <td><center>3.000 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Partida Pop a Shot (ganhar partida multiplayer)</center></td>
                                    <td><center>Até 5 vezes por dia	</center></td>
                                    <td><center>100 pontos	</center></td>
                                    <td><center>500 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Tirar Foto da Tela via Plataforma</center></td>
                                    <td><center>1 vez por dia	</center></td>
                                    <td><center>150 pontos	</center></td>
                                    <td><center>150 pontos</center></td>
                                </tr>
                                <tr>
                                    <td><center>Trivia Museu (cada resposta certa)</center></td>
                                    <td><center>Até 100 vezes durante toda a promoção	</center></td>
                                    <td><center>30 pontos	</center></td>
                                    <td><center>3000 pontos</center></td>
                                </tr>
                            </tbody></table>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


        <!-- <div v-if="tutorial" id="tutorial-handler" ref="tutorial-handler" class="modals bgBlack">
            <div style="width: 100vw;height: 100vh;position: fixed;top: 0;left: 0;" data-response="0" v-on:click="tutorialHandler"></div>
            <div ref="tutorial-handler-content" class="modalGerais">
                <div class="modals__content flex"
                     :style="'background-image: url(\''+$url+'assets/images/modals/box.png\');'">
                    <img class="linhaModais" :src="$url+'assets/icons/modals/linhaModais.svg'" alt="">
                    <div class="modals__content--header alignVerticalStart">
                        <h2>tutorial</h2>
                        <a href="#" class="close" data-response="0" v-on:click="tutorialHandler">
                            <img src="assets/icons/modals/xizinnho.svg" alt="">
                        </a>
                    </div>
                    <div class="modals__content--body alignVertical">
                        <div class="modalGerais__tutorial">
                            <iframe src="https://player.vimeo.com/video/571362576" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";
import Swiper from 'swiper/bundle';

export default {
    name: 'Ranking',
    computed: {
        ...mapGetters([
            'token', 'nickname', 'TotalPoints', 'avatar', 'switch'
        ]),
    },
    data() {
        return {
            timer_countdown: null,
            swiper_imgHeader: null,
            swiper_winners: null,
            timestamp_general: null,
            timestamp_daily: null,
            list_daily: [],
            list_general: [],
            list_daily_friends: [],
            list_general_friends: [],
            list_winner_daily: [],
            list_winner_general: [],
            winner_general:{
                divulgation:false,
                date:'',
                time:'',
                winner:[],
            },
            my_daily_point: 0,
            my_daily_ranking: 0,
            my_general_ranking: 0,
            my_point: 0,
            active_tab: 'geral',
            relogio_restante_dia: '-',
            relogio_restante_geral: '-',
            tutorial: false,
            my_link_video: null,
            my_daily_reward_description: null,
            my_general_reward_description: null,
            my_daily_reward_image: null,
            my_general_reward_image: null,
            my_ranking_general_fin: null,
            my_ranking_general_ini: null,
            my_ranking_daily_fin: null,
            my_ranking_daily_ini: null,
            my_ranking_general: false,
            my_ranking_daily: false,
            reward: [],
            regulation:''
        }
    },
    components: {

    },
    methods: {
        tutorialHandler(e){
            e;
            this.$app.analytics('tutorial_ranking');
            this.tutorial = !this.tutorial;
        },
        //----- general -----//
        generalLoadEvents() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetGeneralRanking', {headers:headers }).then(response => this.generalLoadResponse(response)).catch(error => this.generalLoadError(error.response));
        },
        generalLoadResponse(obj)
        {
            obj;
            // console.log('generalLoadResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_general = obj.data.GetGeneralRanking;
                this.timestamp_general = obj.data.timestamp;
            }

            this.$app.wait(false);
        },
        generalLoadError(obj)
        {
            // console.log('generalLoadError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \general\ -----//
        // ----- general -----//
        generalWinners() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetWinnerGeneral', { headers:headers }).then(response => this.generalWinnersResponse(response)).catch(error => this.generalWinnersError(error.response));
        },
        generalWinnersResponse(obj)
        {
            obj;
            // console.log('generalWinnerResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_winner_general = obj.data.data.winner ?? [];
                this.winner_general = obj.data.data;
            }

            console.log('generalWinnerResponse -> ', this.list_winner_general);

            this.$app.wait(false);
        },
        generalWinnersError(obj)
        {
            //console.log('generalLoadError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \general\ -----//
        // ----- general -----//
        dailyWinners() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetWinnerDaily', { headers:headers }).then(response => this.dailyWinnersResponse(response)).catch(error => this.dailyWinnersError(error.response));
        },
        dailyWinnersResponse(obj)
        {
            obj;
            console.log('dailyWinnersResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_winner_daily = obj.data.data ?? [];
            }

            this.$app.wait(false);
        },
        dailyWinnersError(obj)
        {
            //console.log('generalLoadError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \general\ -----//

        //----- daily -----//
        dailyLoadEvents() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetDailyRanking',{ headers:headers }).then(response => this.dailyLoadResponse(response)).catch(error => this.dailyLoadError(error.response));
        },
        dailyLoadResponse(obj)
        {
            obj;
            // console.log('dailyLoadResponse -> ', obj);

            if (obj.data.status == 'success') {
                this.list_daily = obj.data.GetDailyRanking;
                this.timestamp_daily = obj.data.timestamp;
            }

            this.$app.wait(false);
        },
        dailyLoadError(obj)
        {
            // console.log('dailyLoadError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \general\ -----//

        //----- friend -----//
        dailyFriendLoadEvents() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetUserFriendRanking', { headers:headers })
                .then(response => this.dailyFriendLoadResponse(response))
                .catch(error => this.dailyFriendLoadError(error.response));
        },
        dailyFriendLoadResponse(obj)
        {
            obj;
            // console.log("dailyFriendLoadResponse ->",obj.data);
            if (obj.data.status == 'success') {
                this.list_daily_friends = obj.data.data.daily_ranking;
                this.list_general_friends = obj.data.data.ranking;
            }

            this.$app.wait(false);
        },
        dailyFriendLoadError(obj)
        {
            //console.log('dailyLoadError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        //----- \friend\ -----//

        //----- user -----//
        userRankingLoadEvents() {
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/GetUserRanking', { headers:headers }).then(response => this.userRankingLoadResponse(response)).catch(error => this.userRankingLoadError(error.response));
        },
        userRankingLoadResponse(obj)
        {
            obj;
            // console.log('userRankingLoadResponse -> ', obj);

            if (obj.data.status == 'success') {
                //this.list_userranking = obj.data.data.ranking;
                this.my_daily_ranking = obj.data.data.daily_ranking;
                this.my_general_ranking = obj.data.data.ranking;
                this.my_daily_point = obj.data.data.daily_point;
                this.my_point = obj.data.data.point;
            }

            this.$app.wait(false);
        },
        userRankingLoadError(obj)
        {
            //console.log('userRankingError -> ', obj);
            obj;
            this.$app.wait(false);
        },
        changeTabRanking(tab) {
            if(tab === 'dia') this.active_tab = 'dia';
            if(tab === 'geral') this.active_tab = 'geral';
            if(tab === 'ganhadores') this.active_tab = 'ganhadores';
        },
        //----- \user\ -----//

        //Obtendo infos de ranking (prêmios, regulamento, diversos)
        rankingConfigLoadEvents() {
            // console.log('entrou');
            this.loading = true;
            this.$app.wait(true);
            let headers = { 'Content-Type': 'application/json;charset=UTF-8', 'Access': 'application/json', language: this.$i18n.locale, Authorization : `Bearer ${this.token}`};
            axios.get(this.$url_api + 'api/ranking_config', { headers:headers }).then(response => this.rankingConfigLoadResponse(response)).catch(error => this.rankingConfigLoadError(error.response));
        },
        rankingConfigLoadResponse(obj)
        {
            obj;
            console.log('rankingConfigLoadResponse -> ', obj.data);

            if (obj.data.status == 'success') {
                this.my_link_video                 = obj.data.link_video;
                // this.my_regulamento                = obj.data.regulamento;
                // this.my_regulamento_file           = obj.data.regulamento_file;
                // this.my_points                     = obj.data.points;
                // this.my_daily_reward_description   = obj.data.daily_reward[0].reward_description;
                // this.my_daily_reward_image         = obj.data.daily_reward[0].image;
                // this.my_general_reward_description = obj.data.general_reward[3].reward_description;
                // this.my_general_reward_image       = obj.data.general_reward[3].image;
                this.reward        = obj.data.reward;
                this.my_ranking_general        = obj.data.ranking_general;
                this.my_ranking_general_fin        = obj.data.ranking_general_fin;
                this.my_ranking_general_ini        = obj.data.ranking_general_ini;
                this.my_ranking_daily        = obj.data.ranking_daily;
                this.my_ranking_daily_fin        = obj.data.ranking_daily_fin;
                this.my_ranking_daily_ini        = obj.data.ranking_daily_ini;
                axios.get(this.$url_api + "api/getRegulation").then(reponse=>{
                    this.regulation = reponse.data.data
                });
                // this.rules_image        = obj.data.rules_image;
            }

            this.$app.wait(false);
        },
        rankingConfigLoadError(obj)
        {
            // console.log('rankingConfigError -> ', obj);
            obj;
            this.$app.wait(false);
        }
    },
    created() {

    },
    mounted() {
        
        var root = this;
        this.rankingConfigLoadEvents();

        // console.log('aqui');
        // console.log({generalFin:this.my_ranking_general_fin, tutorial:this.tutorial});

        this.swiper_winners = new Swiper(".ranking__winners--winner", {
            slidesPerView: 5,
            spaceBetween: 15,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
        });

        this.swiper_imgHeader = new Swiper(".ranking__imgHeader--swiper", {
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
            },
        });

        this.timer_countdown = setInterval(function(){
            // countdown diario
            let dt = new Date();
            let segundos_diff = 24*60*60 - (dt.getHours()*3600 + dt.getMinutes()*60 + dt.getSeconds());
            segundos_diff = Math.max(0,segundos_diff);
            let hh = parseInt(segundos_diff/3600);   
            segundos_diff -= hh*3600;
            let mm = parseInt(segundos_diff/60);   
            segundos_diff -= mm*60;
            hh =("0"+hh).slice(-2); // 00
            mm =("0"+mm).slice(-2); // 00 
            segundos_diff =("0"+segundos_diff).slice(-2); // 00
            root.relogio_restante_dia = `${hh}:${mm}:${segundos_diff}`;

            // countdown geral fim 
            let dt_fim = new Date(root.my_ranking_general_fin);
            let diff_geral = parseInt((dt_fim - dt)/1000);
            diff_geral = Math.max(0,diff_geral);
            let g_dia = parseInt(diff_geral/(24*60*60));
            diff_geral -= g_dia*24*60*60;
            let g_hh = parseInt(diff_geral/3600);   
            diff_geral -= g_hh*3600;
            let g_mm = parseInt(diff_geral/60);   
            diff_geral -= g_mm*60;
            g_hh =("0"+g_hh).slice(-2); // 00
            g_mm =("0"+g_mm).slice(-2); // 00 
            diff_geral =("0"+diff_geral).slice(-2); //00

            root.relogio_restante_geral = '';
            if (g_dia > 1)
                root.relogio_restante_geral = `${g_dia}` + root.$i18n.t('account.dias');
            else if (g_dia == 1)
                root.relogio_restante_geral = root.$i18n.t('account.1dia');

            root.relogio_restante_geral += `${g_hh}:${g_mm}:${diff_geral}`;            
        },1000);
        this.$app.analytics('visit_ranking');
        let d = new Date();
        this.actual_date = (d.getHours() + ':' + d.getMinutes() + ' ' + d.getDate() + '/' + d.getMonth() + '/' + d.getFullYear());
        this.generalLoadEvents();
        this.dailyLoadEvents();
        this.userRankingLoadEvents();
        this.dailyFriendLoadEvents();
        this.generalWinners();
        this.dailyWinners();
    },
    beforeDestroy() {
        clearInterval(this.timer_countdown);
    },
    updated: function () {
        if (this.swiper_imgHeader)
            this.swiper_imgHeader.update();

        if (this.swiper_winners)
            this.swiper_winners.update();
    },
}
</script>