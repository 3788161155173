/**
 * Inserts HTML, including <script> tags, into a web page and executes the scripts contained within the HTML string.
 *
 * @param {string} htmlString - The HTML string containing the code to be inserted and executed.
 * @param divId
 * @param callback
 */
export function insertHtmlWithScript(htmlString, divId = null) {
    // Create a temporary div element to handle the HTML
    let tempDiv = document.createElement('div');

    divId ?  (tempDiv.id = divId) : (tempDiv.id = 'vt_template_html');

    // Set the HTML content of the div element with the provided HTML string
    tempDiv.innerHTML = htmlString;

    // Find all <script> tags within the div element
    let scripts = tempDiv.querySelectorAll('script');

    // For each <script> tag found, create a new <script> element in the current document
    scripts.forEach(function (script) {
        let newScript = document.createElement('script');
        if (script.src) {
            // If the <script> tag has a src attribute, set it in the new <script> element
            newScript.src = script.src;
        } else {
            // If there is no src attribute, copy the script's content to the new <script> element
            newScript.textContent = script.textContent;
        }
        // Append the new <script> element to the end of the body for execution
        document.body.appendChild(newScript);
    });

    // Insert the HTML content (without <script> tags) wherever you want on the page
    // For example, you can insert it in the body or in a specific element.
    document.body.appendChild(tempDiv);
}


/**
 * Extracts the scene name from a URL.
 *
 * @param {string} url - The URL from which to extract the scene name.
 * @returns {string|null} - The extracted scene name or null if there is no "/" in the URL.
 */
export function extractSceneNameFromURL(url) {
    // Find the position of the last "/" in the URL
    let lastSlashPosition = url.lastIndexOf("/");

    // Check if the last "/" was found
    if (lastSlashPosition !== -1) {
        // Extract the part of the URL after the last "/"
        let sceneName = url.substring(lastSlashPosition + 1);
        // Replace "%20" with spaces
        sceneName = sceneName.replace(/%20/g, " ");
        return sceneName;
    } else {
        return null; // Returns null if there is no "/" in the URL
    }
}


/**
 * Removes a specific div element from the DOM by its ID.
 *
 * @param {string} elementId - The ID of the div element to be removed.
 * @returns {boolean} - True if the element was successfully removed, false if not found.
 */
export function removeDivById(elementId) {
    // Find the div element by its ID
    let elementToRemove = document.getElementById(elementId);

    // Check if the element exists
    if (elementToRemove !== null) {
        // Remove the element from the DOM
        elementToRemove.parentNode.removeChild(elementToRemove);
    }
}
/**
 *  Focus iframe to enable keyboard input
 *
 * @param {boolean} isMobile - true if mobile device
 */
export function focusUnity(isMobile) {
    if (!isMobile && document.getElementById("iframe")){
        document.getElementById("iframe").focus();
    }
}

/**
 * Converts a string containing HTML data into a modified version with specific styles applied.
 * This function parses the input HTML data, applies styles to <blockquote> elements and <p> elements,
 * and then returns the styled HTML as a string. It focuses on enhancing the appearance of blockquotes
 * by adjusting their visual styling and adding pseudo-elements for decorative quotes. Paragraphs also
 * receive style adjustments for better readability.
 *
 * @param {string} htmlData - The HTML content as a string to be styled.
 * @returns {string} The styled HTML content as a string.
 *
 * ### Usage
 * ```javascript
 * const styledHTML = makeHTML('<blockquote>Your quote here</blockquote><p>Your paragraph here.</p>');
 * document.body.innerHTML = styledHTML;
 * ```
 *
 * ### Styling Details
 * - **Blockquotes**:
 *   - Font size increased to 1.2em
 *   - Width set to 100% for full container width
 *   - Margins set to auto for center alignment, with 25px top and bottom margin
 *   - Italic font style for emphasis
 *   - Font color set to a moderate grey (#555555)
 *   - Padding applied for inner spacing, with a significant left padding
 *   - A solid left border for visual separation and emphasis
 *   - Relative positioning to allow for pseudo-element positioning
 *   - Background color set to a light grey for distinction
 *   - Decorative pseudo-elements (::before) added for quotation marks
 *
 * - **Paragraphs (p)**:
 *   - Font size set to 14px for readability
 *   - Line height set to 1.6 for improved text flow and readability
 *
 * Note: This function requires the DOM environment to operate, as it uses `DOMParser` and `XMLSerializer`.
 */
export function makeHTML(htmlData) {
    if(!htmlData || htmlData === "null") {
        return "";
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlData, 'text/html');

    // Localize todos os elementos <blockquote> dentro do elemento
    let blockquotes = doc.querySelectorAll('blockquote');
    // Aplique estilos a cada elemento <blockquote>
    blockquotes.forEach((blockquote) => {
        // Aplique estilos CSS
        blockquote.style.fontSize = '1.2em';
        blockquote.style.width = '100%';
        blockquote.style.margin = '25px auto';
        blockquote.style.fontStyle = 'italic';
        blockquote.style.color = '#555555';
        blockquote.style.padding = '1.2em 30px 1.2em 75px';
        blockquote.style.borderLeft = '8px solid var(--buttons_color)';
        blockquote.style.lineHeight = '1.6';
        blockquote.style.position = 'relative';
        blockquote.style.background = '#EDEDED';

        // Crie elementos ::before e ::after
        const quoteBefore = document.createElement('span');
        const quoteAfter = document.createElement('span');

        // Aplique estilos para ::before
        quoteBefore.style.fontFamily = 'Open Sans';
        quoteBefore.innerHTML = '"';
        quoteBefore.style.color = 'var(--buttons_color)';
        quoteBefore.style.fontSize = '4em';
        quoteBefore.style.position = 'absolute';
        quoteBefore.style.left = '10px';
        quoteBefore.style.top = '-10px';

        // Aplique estilos para ::after
        quoteAfter.style.content = '';

        // Adicione os elementos ::before e ::after ao bloco de citação
        blockquote.appendChild(quoteBefore);
        blockquote.appendChild(quoteAfter);
    });

    // Definição do parágrafo
    let p = doc.querySelectorAll('p');
    p.forEach((p) => {
        p.style.fontSize = '14px';
        p.style.lineHeight = '1.6';
    });

    // Converta o documento de volta para uma string HTML
    return new XMLSerializer().serializeToString(doc);

}


